import React, {useEffect} from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';
import {StaticImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';
import {graphql, useStaticQuery} from 'gatsby';
import Button from '../../../components/Button';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {getImage} from 'gatsby-plugin-image';

const GridConnectors = () => {
  return (
    <div className="relative flex flex-wrap justify-around mx-8 md:justify-center max-w-7xl md:mx-auto">
      {[
        <StaticImage
          src="../../../images/systems/okta.svg"
          name="okta"
          data-linkactive={true}
          alt="Okta"
          quality={100}
        />,
        <StaticImage
          src="../../../images/systems/azure.svg"
          name="azure"
          data-linkactive={true}
          alt="Azure"
          quality={100}
        />,
        <StaticImage
          src="../../../images/systems/google.svg"
          name="google"
          data-linkactive={true}
          alt="Google"
          quality={100}
        />,
        <StaticImage src="../../../images/systems/gcp.svg" name="gcp" alt="Gcp" quality={100} />,
        <StaticImage
          src="../../../images/systems/tableau.svg"
          name="tableau"
          data-linkactive={true}
          alt="Tableau"
          quality={100}
        />,
        <StaticImage
          src="../../../images/systems/github.svg"
          name="github"
          data-linkactive={true}
          alt="GitHub"
          quality={100}
        />,

        <StaticImage src="../../../images/systems/aws.svg" name="aws" data-linkactive={true} alt="AWS" quality={100} />,
        <StaticImage src="../../../images/systems/cloudflare.svg" name="cloudflare" alt="Cloudflare" quality={100} />,
        <StaticImage src="../../../images/systems/m365.svg" name="m365" alt="Microsoft 365" quality={100} />,
        <StaticImage src="../../../images/systems/jira.svg" name="jira" alt="Jira" quality={100} />,
        <StaticImage
          src="../../../images/systems/generic.svg"
          name="custom"
          data-linkactive={true}
          alt="CSV"
          quality={100}
        />,
        <StaticImage
          src="../../../images/systems/manual.svg"
          name="custom"
          data-linkactive={true}
          alt="Manual"
          quality={100}
        />,
      ].map((img, index) => {
        const systemName = img.props['name'] ?? 'comingsoon';
        const connectorLink = img.props['data-linkactive'] ? `system/${systemName}` : `system/comingsoon`;
        const systemLabel = img.props['alt'] ?? '';
        return (
          <div
            className="hover:scale-110 transition duration-150 ease-in-out m-2 sm:m-4 md:mb-8 md:mx-4 md:h-[124px] md:w-[124px] lg:h-auto lg:w-auto flex justify-center items-center"
            key={index}
          >
            <Link to={connectorLink} state={{state: systemName}}>
              <div className="w-[204px] h-[138px] p-4 bg-white rounded-lg border border-gray-100 justify-start items-start inline-flex">
                <div className="flex flex-col">
                  <div className="h-[64px] w-[64px] mb-2">{img}</div>
                  <div className="p-2 text-xl font-bold leading-7 text-black text-opacity-90">{systemLabel}</div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

const SectionLookingSystem = ({background}) => {
  const bgImageInte = convertToBgImage(getImage(background));

  return (
    <>
      <div className="relative ">
        <section className="relative mb-20 pb-28">
          <BackgroundImage Tag="section" {...bgImageInte} preserveStackingContext className="relative">
            <div className="relative flex flex-col-reverse items-center justify-center w-full px-5 pt-12 pb-8 mb-8 text-white lg:justify-start md:flex-row lg:max-w-5xl 2xl:max-w-6xl lg:mx-auto filter drop-shadow-2xl">
              <div className="relative text-white">
                <h2 className="md:leading-[55px] font-bold text-3xl md:text-4xl text-left pb-2 max-w-md">
                  Don’t see what you’re looking for?
                </h2>
                <div className="py-4 my-2 lg:my-0 md:w-[180px] text-left">
                  <Link to="/product/integrations/request" className="w-full">
                    <Button label="Add your request" variant="secondary" className="w-full" />
                  </Link>
                </div>
                <p className="max-w-md mb-8">
                  Let us know what you're looking for to be added to our pre-release private beta list.
                </p>
              </div>
              <div className="md:absolute z-2 md:right-0 top-[-40px] max-w-[250px] block mx-auto lg:max-w-[364px] relative">
                <StaticImage
                  src="../../../images/systems/systems-next-v2.png"
                  className="md:max-w-[250px] lg:max-w-[364px]"
                  alt="coming soon systems"
                  quality={100}
                />
              </div>
            </div>
          </BackgroundImage>
        </section>
      </div>
    </>
  );
};

const Integrations = ({location}) => {
  useEffect(() => {
    location?.state?.scrollY && window.scroll(0, location?.state?.scrollY);
  }, [location?.state?.scrollY]);

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
      backgroundRussel: file(relativePath: {eq: "bg/bg-section-header.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {backgroundImage, backgroundRussel} = data;

  const bgImage = convertToBgImage(getImage(backgroundImage));
  return (
    <Layout
      seo={
        <Seo
          title="Features | Trustle"
          description="We meet you where you are at with an ever-growing list of connected systems. See how Trustle dives deep to surface important data and keeps your systems secure."
        />
      }
    >
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <section className="py-16 ">
          <div className="max-w-5xl px-5 pt-0 mx-auto text-center">
            <SectionIntro
              hero="Documentation"
              title="Integrations"
              description="We meet you where you are at with an ever-growing list of connected systems. See how Trustle dives deep to surface important data and keeps your systems secure. "
              isPageHeader
            />
            <h2 className="mb-16 text-3xl w-[250px] md:w-full mx-auto font-bold text-center text-blue">
              Select a system to learn more!
            </h2>
          </div>
          <GridConnectors />
        </section>
      </BackgroundImage>
      <section className="pt-16">
        <SectionLookingSystem background={backgroundRussel} />
      </section>
    </Layout>
  );
};

export default Integrations;
